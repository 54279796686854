<template>
  <div class="vh100" style="background: linear-gradient(to top, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="container">
      <div class="card-header text-center" style="background: transparent !important;">


        <div class="row pe-1">
          <div class="col-6 text-start">
            <img src="@/assets/logo.png" style="height: 100px;" />
          </div>
          <div class="col-6 text-end">
            <div class="mt-4 font-weight-light text-white">
              <h1 style="font-size: 45px !important;">สมาชิก</h1>
            </div>
            <div class="en t2 font-weight-light text-white">
              <h6>MEMBER</h6>
            </div>
          </div>

        </div>

      </div>

      <div class="card-body">
        <div class="row text-end text-white"><span @click="edit" class="title-text"
            style="font-size: 18px !important;">แก้ไขโปรไฟล์ ></span>
        </div>
        <div class="row justify-content-center align-items-center">
          <div class="col-11 mt-2">
            <div class="box-profile silver_p">
              <img src="@/assets/profile_crow.png"
                style="
                                                                                                                                                                        height: 50px;
                                                                                                                                                                        position: absolute;
                                                                                                                                                                        margin-top: 2rem;
                                                                                                                                                                        margin-left: auto;
                                                                                                                                                                        margin-right: auto;
                                                                                                                                                                        left: 0;
                                                                                                                                                                        right: 0;
                                                                                                                                                                        text-align: center;
                                                                                                                                                                      " />
              <img :src="ProfireLine.pictureUrl"
                style="
                                                                                                                                                                        height: 150px;
                                                                                                                                                                        margin-top: 4rem;
                                                                                                                                                                        margin-bottom: 3rem;
                                                                                                                                                                        position: relative;
                                                                                                                                                                        border-radius: 50%;
                                                                                                                                                                        border: 3px solid white;
                                                                                                                                                                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                                                                                                                                                                      " />

            </div>
          </div>
        </div>

      </div>
      <div class="container text-center justify-content-center align-items-center d-flex">

        <div class="font-weight-light text-white">
          <p style="font-size: 27px;">คุณ {{ Profire.firstname }} {{ Profire.lastname }} </p>
          <p style="font-size: 22px;">เบอร์โทรศัพท์ {{ Profire.tel }} </p>

          <div class="rank_box mt-4 ps-4 pe-4 align-items-centerjustify-content-center">

            <p style="font-size: 27px; color:white">ระดับสมาชิก : {{ Profire.ranks[0].name }} </p>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";

export default defineComponent({
  name: "Profile",
  components: {
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };


    return {
      edit,
      fn_status,
      ProfireLine,
      Profire
    };
  },
});

</script>

<style scoped>
.card-header {
  border-bottom: none;
  margin-bottom: 1rem;
  background-color: transparent;
  height: 100px;
  min-height: 100px;
}

.rank_box {
  border: none;
  border-radius: 25px;
  background-color: #707070;
  padding: 8px 8px 1px 8px;
}

.address-box {
  background-color: #BED630;
  min-height: 300px;
  overflow: scroll;
  padding: 16px;

}

.address-box-header {
  position: relative;
}

.address-box-body {
  position: relative;
  min-height: 250px;
  height: 250px;
  overflow-y: scroll;

}
</style>
